export const state = () => ({
    type: null,
    data: {},
    step: 'start',
    extra: null,
});

export const mutations = {
    setSheet(state, { type, data, step = null, extra = null }) {
        state.type = type;
        state.data = data;
        state.extra = extra;
        if (step) {
            state.step = step;
        }
    },
    setStep(state, step) {
        state.step = step;
    },
    closeSheet(state) {
        state.type = null;
        state.addressChosen = false;
        state.data = {};
        state.step = 'start';
    },
};

export const getters = {
    type: state => state.type,
    data: state => state.data,
    step: state => state.step,
    extra: state => state.extra,
};

export const state = () => ({
    dishSelection: {
        food_option_groups: [],
    },
    type: 'single',
});

export const mutations = {
    setDish(state, dishSelection) {
        state.single = true;
        state.dishSelection = {
            ...dishSelection,
            food_option_groups: [],
        };
    },
    setFoodOption(state, { group, option }) {
        const currentOptionGroup = state.dishSelection.food_option_groups.find(item => item.id === group.id);
        if (currentOptionGroup) {
            const multipleChoice = currentOptionGroup.multiple_choice;
            if (multipleChoice) {
                const currentOptionIndex = currentOptionGroup.food_options.findIndex(x => x.id === option.id);
                if (currentOptionIndex !== -1) {
                    currentOptionGroup.food_options.splice(currentOptionIndex, 1);
                } else {
                    currentOptionGroup.food_options.push(option);
                }
            } else {
                currentOptionGroup.food_options = [option];
            }
        } else {
            const optionGroup = {
                ...group,
                food_options: [option],
            };
            state.dishSelection.food_option_groups.push(optionGroup);
        }
    },
    setType(state, type) {
        state.type = type;
    },
    resetDishSelection(state) {
        state.dishSelection = {
            food_option_groups: [],
        };
    },
};

export const getters = {
    dishSelection: state => state.dishSelection,
    optionGroup: state => id => {
        return state.dishSelection.food_option_groups.find(group => group.id === id);
    },
    type: state => state.type,
};

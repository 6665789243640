export const state = () => ({
    routes: {},
    loading: false,
    splashSeen: false,
    appHeight: null,
    statusBarVisible: true,
    desktopCheckoutStarted: false,
    transition: 'left',
    concept: 'happy-takeaway',
    vat: 'excl',
    marketplace: null,
});

export const mutations = {
    setDesktopCheckoutStarted(state, bool) {
        state.desktopCheckoutStarted = bool;
    },
    setLoading(state, loading) {
        state.loading = loading;
    },
    setSplashSeen(state, seen) {
        state.splashSeen = seen;
    },
    routes(state, routes) {
        state.routes = routes;
    },
    appHeight(state, appHeight) {
        state.appHeight = appHeight;
    },
    setStatusBarVisible(state, visible) {
        state.statusBarVisible = visible;
    },
    setTransition(state, direction) {
        state.transition = direction;
    },
    setConcept(state, concept) {
        state.concept = concept;
    },
    setVat(state, option) {
        localStorage.setItem('vat', option);
        state.vat = option;
    },
    setMarketplace(state, option) {
        localStorage.setItem('marketplace', option);
        state.marketplace = option;
    },
};

export const getters = {
    routes: state => state.routes,
    loading: state => state.loading,
    appHeight: state => state.appHeight,
    splashSeen: state => state.splashSeen,
    isAuthenticated: state => state.auth.loggedIn,
    loggedInUser: state => state.auth.user,
    statusBarVisible: state => state.statusBarVisible,
    desktopCheckoutStarted: state => state.desktopCheckoutStarted,
    transition: state => state.transition,
    concept: state => state.concept,
    vat: state => state.vat,
    marketplace: state => state.marketplace,
};

export const state = () => ({
    currentSupplier: null,
    theme: null,
    supplierCache: {} // Add cache for supplier data
});

export const mutations = {
    setCurrentSupplier(state, supplier) {
        state.currentSupplier = supplier;
    },
    setTheme(state, theme) {
        state.theme = theme;
    },
    addToCache(state, { subdomain, data }) {
        state.supplierCache[subdomain] = data;
    }
};

export const actions = {
    async fetchSupplierBySubdomain({ commit, state }, subdomain) {
        try {
            // Check cache first
            if (state.supplierCache[subdomain]) {
                const cachedData = state.supplierCache[subdomain];
                commit('setCurrentSupplier', cachedData.supplier);
                commit('setTheme', cachedData.theme);
                return cachedData;
            }

            // TODO: Replace with actual API call
            // Temporary test data for det-rene-brod
            if (subdomain === 'det-rene-brod') {
                const testData = {
                    supplier: {
                        id: 1,
                        name: 'Det Rene Brød',
                        subdomain: 'det-rene-brod',
                        logo: '/suppliers/det-rene-brod/images/logo.png',
                        logoDark: '/suppliers/det-rene-brod/images/logo-dark.png',
                        cover: '/suppliers/det-rene-brod/images/cover.jpg',
                        portrait: '/suppliers/det-rene-brod/images/portrait.jpg',
                        description: 'Økologisk brød og bagværk',
                        locations: [
                            {
                                id: 1,
                                name: 'Rosenvaengets Allé',
                                slug: 'det-rene-brod-rosenvaengets-alle',
                            },
                        ],
                    },
                    theme: {
                        primaryColor: '#8B4513',
                        secondaryColor: '#F5DEB3',
                        textColor: '#2C1810',
                        textColorLight: '#8B4513',
                        backgroundColor: '#4F453B',
                        backgroundColorAlt: '#FFE17E',
                        buttonColor: '#4F453B',
                        buttonTextColor: '#FFE17E',
                        favicon: '/suppliers/det-rene-brod/icons/favicon.ico',
                        favicons: {
                            16: '/suppliers/det-rene-brod/icons/favicon-16.png',
                            32: '/suppliers/det-rene-brod/icons/favicon-32.png',
                            96: '/suppliers/det-rene-brod/icons/favicon-96.png',
                        },
                    },
                };
                commit('setCurrentSupplier', testData.supplier);
                commit('setTheme', testData.theme);
                commit('addToCache', { subdomain, data: testData });
                return testData;
            }
            // TODO: Replace with actual API call
            // Temporary test data for michael-h-konditoriet
            if (subdomain === 'michael-h-konditoriet') {
                const testData = {
                    supplier: {
                        id: 1,
                        name: 'Michael H',
                        subdomain: 'michael-h-konditoriet',
                        logo: '/suppliers/michael-h-konditoriet/images/logo.svg',
                        logoDark: '/suppliers/det-rene-brod/images/logo-dark.png',
                        cover: '/suppliers/det-rene-brod/images/cover.jpg',
                        portrait: '/suppliers/det-rene-brod/images/portrait.jpg',
                        description: 'Økologisk brød og bagværk',
                        locations: [
                            {
                                id: 1,
                                name: 'Gentoftegade',
                                slug: 'michael-h-konditoriet-gentoftegade',
                            },
                        ],
                    },
                    theme: {
                        primaryColor: '#8B4513',
                        secondaryColor: '#F5DEB3',
                        textColor: '#2C1810',
                        textColorLight: '#8B4513',
                        backgroundColor: '#A59784',
                        backgroundColorAlt: '#59784',
                        buttonColor: '#A59784',
                        buttonTextColor: '#00000',
                        favicon: '/suppliers/det-rene-brod/icons/favicon.ico',
                        favicons: {
                            16: '/suppliers/det-rene-brod/icons/favicon-16.png',
                            32: '/suppliers/det-rene-brod/icons/favicon-32.png',
                            96: '/suppliers/det-rene-brod/icons/favicon-96.png',
                        },


                    },
                };
                commit('setCurrentSupplier', testData.supplier);
                commit('setTheme', testData.theme);
                commit('addToCache', { subdomain, data: testData });
                return testData;
            }

            if (subdomain === 'aerligt') {
                const testData = {
                    supplier: {
                        id: 1,
                        name: 'Ærligt',
                        subdomain: 'aerligt',
                        logo: '/suppliers/supplier-placeholder.png',
                        logoDark: '/suppliers/supplier-placeholder.png',
                        cover: '/suppliers/supplier-placeholder.png',
                        portrait: '/suppliers/supplier-placeholder.png',
                        description: 'Økologisk brød og bagværk',
                        locations: [
                            {
                                id: 1,
                                name: 'Nørre Voldgade',
                                slug: 'aerligt-norre-voldgade',
                            },
                        ],
                    },
                    theme: {
                        primaryColor: '#8B4513',
                        secondaryColor: '#F5DEB3',
                        textColor: '#080808',
                        textColorLight: '#8B4513',
                        backgroundColor: '#1E1E1E',
                        backgroundColorAlt: '#ffffff',
                        buttonColor: '#1E1E1E',
                        buttonTextColor: '#EEE',
                        favicon: '/suppliers/det-rene-brod/icons/favicon.ico',
                        favicons: {
                            16: '/suppliers/det-rene-brod/icons/favicon-16.png',
                            32: '/suppliers/det-rene-brod/icons/favicon-32.png',
                            96: '/suppliers/det-rene-brod/icons/favicon-96.png',
                        },

                        
                    },
                };
                commit('setCurrentSupplier', testData.supplier);
                commit('setTheme', testData.theme);
                commit('addToCache', { subdomain, data: testData });
                return testData;
            }


            if (subdomain === 'vinthers-bageri') {
                const testData = {
                    supplier: {
                        id: 1,
                        name: 'Vinthers Bageri',
                        subdomain: 'vinthers-bageri',
                        logo: '/suppliers/vinthers-bageri/images/logo.png',
                        logoDark: '/suppliers/vinthers-bageri/images/logo-dark.png',
                        cover: '/suppliers/vinthers-bageri/images/cover.jpg',
                        portrait: '/suppliers/vinthers-bageri/images/portrait.jpg',
                        description: 'Friskbagt brød, croissanter, dessertkager og kager til kaffen',
                        locations: [
                            {
                                id: 1,
                                name: 'Vinthers Bageri',
                                slug: 'vinthers-bageri-vinthers-bageri',
                            },
                        ],
                    },
                    theme: {
                        primaryColor: '#8B4513',
                        secondaryColor: '#FFFFFF',
                        textColor: '#2C1810',
                        textColorLight: '#8B4513',
                        backgroundColor: '#ED6B38',
                        backgroundColorAlt: '#FFFFFF',
                        buttonColor: '#ED6B38',
                        buttonTextColor: '#00000',
                        favicon: '/suppliers/det-rene-brod/icons/favicon.ico',
                        favicons: {
                            16: '/suppliers/det-rene-brod/icons/favicon-16.png',
                            32: '/suppliers/det-rene-brod/icons/favicon-32.png',
                            96: '/suppliers/det-rene-brod/icons/favicon-96.png',
                        },
                    },
                };
                commit('setCurrentSupplier', testData.supplier);
                commit('setTheme', testData.theme);
                commit('addToCache', { subdomain, data: testData });
                return testData;
            }

            // For other subdomains, call the actual API
            const { data } = await this.$axios.get(`/api/suppliers/subdomain/${subdomain}`);
            commit('setCurrentSupplier', data.supplier);
            commit('setTheme', data.theme);
            commit('addToCache', { subdomain, data });
            return data;
        } catch (error) {
            console.error('Error fetching supplier:', error);
            return null;
        }
    },
};

export const getters = {
    isWhitelabel: state => !!state.currentSupplier,
    getTheme: state => state.theme || {},
    getSupplier: state => state.currentSupplier,
}; 
export const state = () => ({
    sideDrawerType: null,
    sideDrawerData: {},
});

export const mutations = {
    setSideDrawer(state, { type, data }) {
        state.sideDrawerType = type;
        state.sideDrawerData = data;
    },
    closeSideDrawer(state) {
        state.sideDrawerType = null;
        state.sideDrawerData = {};
    },
};

export const getters = {
    sideDrawerType: state => state.sideDrawerType,
    sideDrawerData: state => state.sideDrawerData,
};

import { Plugins } from '@capacitor/core';
import { mapGetters } from 'vuex';
const { Geolocation, Toast } = Plugins;

export const state = () => ({
    location: null,
    locationLoading: false,
    locationAllowed: null,
    locationLastSaved: null,
});

export const mutations = {
    setLocation(state, { longitude, latitude }) {
        state.location = { longitude, latitude };
    },
    clearLocation(state) {
        state.location = null;
    },
    locationLoading(state, locationLoading) {
        state.locationLoading = locationLoading;
    },
    locationAllowed(state, locationAllowed) {
        state.locationAllowed = locationAllowed;
    },
    setLocationLastSaved(state, lastSaved) {
        state.locationLastSaved = lastSaved;
    }
};

export const actions = {
    async getLocation({ commit, dispatch, rootGetters }, setLoading = true) {
        try {
            /* if (state.locationLastSaved && state.location) {
                const timeNow = Math.floor(Date.now()/1000);
                if ((timeNow - state.locationLastSaved) < 3600) { // If less than one hour ago, don't check location again
                    return true;
                }
            } */
            
            if (setLoading) {
                commit('locationLoading', true);
            }
            
            
            // If got last location less than 1 hour ago, set this as current location
            if (rootGetters['order/lastLocation']) {
                let timeNow = Date.now() / 1000;
                let lastLocation = rootGetters['order/lastLocation'];
                if ((timeNow - lastLocation.time) < 3600) {
                    commit('setLocation', {latitude: lastLocation.lat, longitude: lastLocation.lng})
                    commit('locationLoading', false);
                    return true;
                }
            }
            
            const { coords } = await Geolocation.getCurrentPosition();
            commit('setLocation', coords);
            commit('locationLoading', false);
            commit('locationAllowed', true);
            commit('setLocationLastSaved', Math.floor(Date.now()/1000));
            commit('order/setLastLocation', {
                lat: coords.latitude,
                lng: coords.longitude,
                time: Math.floor(Date.now() / 1000),
            },
            {
                root: true
            });
        } catch (error) {
            // const coords = {
            //     latitude: 55.818243,
            //     longitude: 12.542889,
            // };
            // commit('setLocation', coords);

            // Set pickup (only if no address)

            this.$bugsnag.notify(new Error(error));
            commit('locationLoading', false);
            commit('locationAllowed', false);
            console.log({ error });
            /* if (!window.location.href.includes('/order/')) {
                dispatch('toast/setToast', { msg: 'Lokation blokeret', duration: 3 }, { root: true });
            } */
        }
    },
    initLocationInterval({ state, dispatch }) {
        // 5 min interval
        /* setInterval(() => {
            if (state.locationAllowed && !state.locationLoading) {
                dispatch('updateLocation');
            }
        }, 300000); */
    },
    async updateLocation({ commit }) {
        commit('locationLoading', true);
        const { coords } = await Geolocation.getCurrentPosition();
        commit('locationLoading', false);
        commit('setLocation', coords);
    },
};

export const getters = {
    location: state => state.location,
    locationLoading: state => state.locationLoading,
    locationAllowed: state => state.locationAllowed,
    locationLastSaved: state => state.locationLastSaved,
};

export const state = () => ({
    type: null,
    data: {},
});

export const mutations = {
    setModal(state, { type, data }) {
        state.type = type;
        state.data = data;
    },
    closeModal(state) {
        state.type = null;
        state.data = {};
    },
};

export const getters = {
    type: state => state.type,
    data: state => state.data,
};

export const state = () => ({
    categories: null,
    currentCategory: null,
    restaurants: [],
    cancelTokenSource: null,
    restaurantsLoading: 'false',
});

export const actions = {
    async getCategories({ commit, rootState }) {
        try {
            const options = {
                params: {
                    // 'filter[has_restaurants]': true,
                },
            };
            const { data } = await this.$axios.get('/restaurant-categories', options);
            commit('setCategories', data);
        } catch (error) {
            this.$bugsnag.notify(new Error(error));
            console.log({ error });
        }
    },
    async getRestaurants({ commit, state, rootState, rootGetters, }, concept) {
        commit('setRestaurantsLoading', true);

        //If any earlier getRestaurants calls is pending, cancel them.
        if (state.cancelTokenSource) {
            state.cancelTokenSource.cancel('Operation canceled due to new request.')
        }
        const newCancelTokenSource = this.$axios.CancelToken.source();

        try {

            // const deliveryMethod = rootState.order.deliveryMethod || 'pickup';
            // let deliveryMethodFilter = deliveryMethod === 'pickup' ? 'pickup' : 'self-deliverit-tribe';
            let deliveryMethodFilter = 'pickup-self-deliverit-tribe-mover';
            let options = {
                params: {
                    include: 'locations,categories',
                    'filter[by_delivery_method]': deliveryMethodFilter,
                },
                cancelToken: newCancelTokenSource.token // Pass the cancel token
            };
            if (concept) {
                let key = 'filter[by_concept]';
                options.params[key] = concept;
            }
            const location = rootState.location.location;
            const lastSavedLocation = rootGetters['order/lastLocation'];
            const address = rootState.order.address;
            if (!rootGetters['order/isDelivery'] && location) {
                options.params.lat = location.latitude;
                options.params.lng = location.longitude;
            } else if (!rootGetters['order/isDelivery'] && lastSavedLocation) {
                options.params.lat = lastSavedLocation.lat;
                options.params.lng = lastSavedLocation.lng;
            } else if (address && rootGetters['order/isDelivery']) {
                options.params.lat = address.lat;
                options.params.lng = address.lng;
            }
        commit('setCancelTokenSource', newCancelTokenSource); 
            const { data } = await this.$axios.get('/restaurants', options);
            const isDelivery = rootGetters['order/isDelivery'];
            if (isDelivery) {
                data.filter(x => x.inside_radius);
            }
            if (!isDelivery && location || !isDelivery && lastSavedLocation) {
                data.filter(x => x.locations.filter(y => y.pickup_option));
                data.sort((a, b) => (a.distance > b.distance ? 1 : b.distance > a.distance ? -1 : 0));
            }

            data.forEach((restaurant) => {
                let sorting = 0;
                if (restaurant.launching_soon) {
                    sorting -= 3;
                } else {
                    sorting++;
                }
                if (restaurant.kitchen_is_open) {
                    sorting++;
                    // sorting++;
                }
                if (restaurant.temp_closed) {
                    sorting -= 2;
                }
                if (!restaurant.launching_soon && !restaurant.temp_closed && restaurant.locations.length) {
                    if (restaurant.locations.filter(x => x.preorder_enabled).length) {
                        sorting++;
                    } else {
                        sorting--;
                    }
                }
                restaurant.sorting = sorting;
            })
            // data.sort((a, b) => (b.launching_soon ? 0 : 1) - (a.launching_soon ? 0 : 1));
            // data.sort((a, b) => (a.kitchen_is_open > b.kitchen_is_open ? -1 : 1));
            data.sort((a, b) => (b.sorting - a.sorting));
            commit('setRestaurants', data);
            commit('setRestaurantsLoading', false);
            commit('setCancelTokenSource', null) //Removes cancel token after call has been resolved.
        } catch (error) {
            console.log({ error });
            commit('setRestaurantsLoading', false);
        }
    },
};

export const mutations = {
    setCategories(state, categories) {
        state.categories = categories;
    },
    setCurrentCategory(state, category) {
        state.currentCategory = category;
    },
    setRestaurants(state, restaurants) {
        state.restaurants = restaurants;
    },
    setRestaurantsLoading(state, val) {
        state.restaurantsLoading = val;
    },
    setCancelTokenSource(state, cancelTokenSource) {
        state.cancelTokenSource = cancelTokenSource; // New mutation to set the cancel token source
    }
};

export const getters = {
    categories(state) {
        return state.categories;
    },
    currentCategory(state) {
        return state.currentCategory;
    },
    restaurants(state) {
        return state.restaurants;
    },
    restaurantsLoading(state) {
        return state.restaurantsLoading;
    }
};

import { v4 as uuidv4 } from 'uuid';

export const state = () => ({
    open: false,
    items: [],
    restaurantLocation: null,
    deliveryMethod: 'pickup',
    deliveryOptionId: null,
    delivery: null,
    bagFee: 0,
    serviceFee: 0,
    userPaymentMethodId: null,
    applePaySelected: false,
    invoiceSelected: false,
    address: null,
    cartVerified: false,
    coupon: null,
    orders: {},
    lastLocation: null,
    homeRoute: '/',
    preOrderTime: null,
    groupCartUUID: null,
    groupCartSlave: false,
    groupDataAssociations: {},
    tempName: '',
    lastSeenSubPage: ''
});

export const mutations = {
    setHomeRoute(state, homeRoute) {
        state.homeRoute = homeRoute;
    },
    setLastLocation(state, lastLocation) {
        state.lastLocation = lastLocation;
    },
    setBagFee(state, bagFee) {
        state.bagFee = bagFee;
    },
    setServiceFee(state, serviceFee) {
        state.serviceFee = serviceFee;
    },
    setDeliveryMethod(state, deliveryMethod) {
        state.deliveryMethod = deliveryMethod;
    },
    setOpen(state, open) {
        state.open = open;
    },
    setItems(state, items) {
        state.items = items;
    },
    setRestaurantLocation(state, restaurantLocation) {
        state.restaurantLocation = restaurantLocation;
    },
    setDeliveryOptionId(state, deliveryOptionId) {
        state.deliveryOptionId = deliveryOptionId;
    },
    setDelivery(state, delivery) {
        state.delivery = delivery;
    },
    setUserPaymentMethodId(state, userPaymentMethodId) {
        state.userPaymentMethodId = userPaymentMethodId;
        state.invoiceSelected = false;
    },
    addDish(state, dish) {
        const lineItem = {
            ...dish,
            quantity: dish.quantity ? dish.quantity : 1,
            uuid: uuidv4(),
        };
        state.items = [...state.items, lineItem];
    },
    updateDish(state, { uuid, quantity }) {
        const lineItemIndex = state.items.findIndex(item => item.uuid === uuid);
        if (quantity) {
            state.items[lineItemIndex].quantity = quantity;
        } else {
            state.items.splice(lineItemIndex, 1);
        }
    },
    removeSlaveGroupCart(state, name){
        state.items = state.items.filter(item => item.added_by_name !== name)
    },
    setAddress(state, address) {
        state.address = address;
    },
    setCartVerified(state, verified) {
        state.cartVerified = verified;
    },
    setCoupon(state, coupon){
        state.coupon = coupon;
    },
    removeCoupon(state){
        state.coupon = null;
    },
    setApplePay(state, modifier){
        state.applePaySelected = modifier;
    },
    clearCart(state) {
        state.items = [];
        state.coupon = null;
    },
    clearAddress(state) {
        state.address = null;
    },
    resetOrder(state) {
        state.restaurantLocation = null;
        state.deliveryOptionId = null;
        state.delivery = null;
        state.userPaymentMethodId = null;
        state.preOrderTime = null;
        state.groupCartUUID = null;
        state.groupDataAssociations = {}
    },
    saveOrder(state, { key, val }) {
        state.orders[key] = val;
    },
    deleteOrder(state, key) {
        delete state.orders[key];
    },
    clearOrders(state) {
        state.orders = {};
    },
    setPreorderTime(state, time){
        state.preOrderTime = time;
    },
    setGroupCartUUID(state, UUID){
        state.groupCartUUID = UUID;
    },
    setGroupCartSlave(state, slave){
        state.groupCartSlave = slave;
    },
    setTempName(state, name){
        state.tempName = name;
    },
    setGroupAssociations(state, assoc){
        state.groupDataAssociations = assoc
    },
    setInvoiceSelected(state, modifier){
        state.invoiceSelected = modifier;
        state.userPaymentMethodId = null;
    },
    setLastSeenSubPage(state, page){
        state.lastSeenSubPage = page;
    }
};

export const actions = {
    addDish({ commit }, dish) {
        commit('addDish', dish);
        commit('setCartVerified', false);
    },
    updateDish({ commit }, { uuid, quantity }) {
        commit('updateDish', { uuid, quantity });
        commit('setCartVerified', false);
    },
    changeDeliveryMethod({ commit, dispatch, rootGetters, state }, { method, address, onLoad = false }) {
        let wasPickup = state.deliveryMethod === 'pickup' ? true : false;
        commit('setDeliveryMethod', method);

        if (method === 'delivery') {
            if (address) {
                const location = {
                    latitude: address.lat,
                    longitude: address.lng,
                };
                commit('setLocation', { location });
            } else {
                // commit('sheet/setSheet', { type: 'Address' }, { root: true });

                const user = rootGetters.loggedInUser;
                if (user && user.addresses.length) {
                    commit('modal/setModal', { type: 'AddressPick' }, { root: true });
                } else {
                    if (wasPickup) {
                        if (state.address) {
                            // Do nothing
                        } else {
                            if (!onLoad) {
                                commit('modal/setModal', { type: 'AddressCheck' }, { root: true }); 
                            }
                        }
                    } else {
                        if (!onLoad) {
                            commit('modal/setModal', { type: 'AddressCheck' }, { root: true });
                        }
                    }
                }
            }
        } else {
            // commit('location/clearLocation', null, { root: true });
            // dispatch('location/getLocation', null, { root: true });
        }
    },
};

export const getters = {
    homeRoute: state => state.homeRoute,
    lastLocation: state => state.lastLocation,
    bagFee(state, getters, rootState, rootGetters){
        if (rootGetters.vat === 'excl') {
            return state.bagFee * 0.8;
        } else {
            return state.bagFee;
        }
    },
    serviceFee(state, getters, rootState, rootGetters){
        if (rootGetters.vat === 'excl') {
            return state.serviceFee * 0.8;
        } else {
            return state.serviceFee;
        }
    },
    deliveryMethod: state => state.deliveryMethod,
    isDelivery: state => state.deliveryMethod === 'delivery',
    restaurantLocation: state => state.restaurantLocation,
    deliveryOptionId: state => state.deliveryOptionId,
    delivery: state => state.delivery,
    userPaymentMethodId: state => state.userPaymentMethodId,
    open: state => state.open,
    items: state => state.items,
    itemsCount: state => state.items.reduce((sum, x) => sum + x.quantity, 0),
    originalPrice(state, getters, rootState, rootGetters){
        let originalPrice = state.items.reduce((sum, x) => {
            const extraArr = x.food_option_groups.map(group => {
                const optionsExtra = group.food_options.reduce((sum, x) => sum + x.price, 0);
                return optionsExtra;
            });
            const extra = extraArr.reduce((a, b) => a + b, 0);
            const price = x.menu ? x.price_menu : (x.original_price ? x.original_price : x.price);
            return sum + (price + extra) * x.quantity;
        }, 0)
        if (rootGetters.vat === 'excl') {
            return (Math.round(originalPrice * 100) / 100) * 0.8;
        } else {
            return Math.round(originalPrice * 100) / 100;
        }
    },
    totalPrice(state, getters, rootState, rootGetters){
        let originalPrice = state.items.reduce((sum, x) => {
            const extraArr = x.food_option_groups.map(group => {
                const optionsExtra = group.food_options.reduce((sum, x) => sum + x.price, 0);
                return optionsExtra;
            });
            const extra = extraArr.reduce((a, b) => a + b, 0);
            const price = x.menu ? x.price_menu : (x.original_price ? x.original_price : x.price);
            return sum + (price + extra) * x.quantity;
        }, 0);
        let output = originalPrice;
        if (state.coupon && state.coupon.type && state.coupon.amount){
            if (state.coupon.type === "percentage"){
                output = Math.round((originalPrice - originalPrice * state.coupon.amount / 100) * 100) / 100;
            }
            else if(state.coupon.type === "cash"){
                output = Math.round((Math.max(0, originalPrice - state.coupon.amount) * 100)) / 100;
            }
        }
        if (rootGetters.vat === 'excl') {
            return (Math.round(output * 100) / 100) * 0.8;
        } else {
            return Math.round(output * 100) / 100;
        }
    },
    address: state => state.address,
    coupon: state => state.coupon,
    cartVerified: state => state.cartVerified,
    orders: state => state.orders,
    applePaySelected: state => state.applePaySelected,
    preorderTime: state => state.preOrderTime,
    groupCartUUID: state => state.groupCartUUID,
    groupCartSlave: state => state.groupCartSlave,
    tempName: state => state.tempName,
    groupAssociations: state => state.groupDataAssociations,
    invoiceSelected: state => state.invoiceSelected,
    lastSeenSubPage: state => state.lastSeenSubPage,
};

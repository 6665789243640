export const state = () => ({
    msg: null,
    info: false,
    showClose: true,
});

export const mutations = {
    setMsg(state, msg) {
        state.msg = msg;
    },
    setInfo(state, info) {
        state.info = info;
    },
    showClose(state, showClose) {
        state.showClose = showClose;
    },
    closeToast(state) {
        state.msg = null;
        state.info = false;
        state.showClose = true;
    },
};

export const actions = {
    setToast({ commit }, { msg = 'Hov der gik noget galt. Prøv igen', duration = 0, info = false }) {
        commit('setMsg', msg);
        commit('setInfo', info);
        if (duration) {
            commit('showClose', false);
            setTimeout(() => {
                commit('closeToast');
            }, duration * 1000);
        }
    },
};

export const getters = {
    msg: state => state.msg,
    info: state => state.info,
    showClose: state => state.showClose,
};

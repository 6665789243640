export const state = () => ({
    require: ['restaurantCategories', 'restaurant'],
    pendingState: {'restaurantCategories': true, 'restaurant': true},
    chainTransition: false,
});

export const mutations = {
    updateState(state, {page, pending}){
        state.pendingState[page] = pending;
    },
    ghostDelivery(state, condition){
        state.chainTransition = condition;
    }
};

export const getters = {
    allPagesLoaded(state){
        return state.require.every(i => state.pendingState[i] === false);
    },
    chainTransition(state){
        return state.chainTransition;
    }
};
